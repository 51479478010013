import { useState } from 'react';
import Cookies from 'js-cookie';

import "../Css/Form.css";


const ValidateMandatory = (val) => {
    return val.trim() !== "";
};
const ValidateEmail = (val) => {
    const REGEX_EMAIL1 = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    const REGEX_EMAIL2 = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!REGEX_EMAIL1.test(val))
        return false;

    if (!REGEX_EMAIL2.test(val))
        return false;
    
    return true;
};
const ValidatePhone = (val) => {
    return val.trim() !== "";
};

const getStatusButton = (sendingStatus) => {
    switch (sendingStatus) {
        case "sending": return <>שולח...</>;
        case "sent":    return <>נשלח בהצלחה!</>;
        case "error":   return <>שגיאה</>;
        case "none":
        default:        return <>שליחה</>;
    }
};
const getStatusMessage = (sendingStatus) => {
    switch (sendingStatus) {
        case "sent":    return <><b style={{color:'#008800'}}>תודה על פניתכם!</b>פניתכם נקלטה ואנו ניצור<br />אתכם קשר בהקדם</>;
        case "error":   return <><b style={{color:'#AA0000'}}>אירעה שגיאה!</b>לצערנו בקשתכם לא נקלטה,<br />אבל אתם מוזמנים ליצור עימנו קשר<br />באמצעות הערוצים האחרים</>;
        default:        return <></>;
    }
};


const Form = (props) => {
    const [stateFormSending, setStateFormSending] = useState("none");
    const [stateFormSend, setStateFormSend] = useState(false);
    const [stateFieldName, setStateFieldName] = useState("");
    const [stateFieldEmail, setStateFieldEmail] = useState("");
    const [stateFieldPhone, setStateFieldPhone] = useState("");
    const [stateFieldSubject, setStateFieldSubject] = useState("");
    const [stateFieldMessage, setStateFieldMessage] = useState("");

    const onButtonClick = () => {
        if (stateFormSending !== "none")
            return;

        setStateFormSend(true);

        if (ValidateMandatory(stateFieldName) &&
            ValidateEmail(stateFieldEmail) &&
            ValidatePhone(stateFieldPhone) &&
            ValidateMandatory(stateFieldSubject) &&
            ValidateMandatory(stateFieldMessage) ) 
        {
            setStateFormSending("sending");

            const params = {
                name: stateFieldName,
                email: stateFieldEmail,
                phone: stateFieldPhone,
                subject: stateFieldSubject,
                message: stateFieldMessage
            };

            try {
                fetch('./form.aspx', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'X-CSRF-Token': Cookies.get("X-CSRF-Token")
                    },
                    body: Object.keys(params).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]); }).join('&')
                })
                .then(
                    (response) => {
                        response.json().then(
                            (sucss) => {
                                if (sucss.status === "OK")
                                    setStateFormSending("sent");
                                else
                                    setStateFormSending("error");
                                        },
                            (error) => {
                                setStateFormSending("error");
                            }
                        )
                    },
                    (error) => {
                        setStateFormSending("error");
                    }
                );
            } 
            catch (e) {
                setStateFormSending("error");
            }
        }
        else
        {
        }
    };


    return (
        <>
            <div className={`${props.pageType === 'ContactUs' ? 'box-padding' : ''} form-grid`}>
                <span>
                    <input 
                        type="text"
                        placeholder="השם שלך*"
                        required
                        value={stateFieldName}
                        onChange={(event) => { setStateFieldName(event.target.value) }}
                    />
                    { ValidateMandatory(stateFieldName) || !stateFormSend || <span className="FormValidationMessage">*שדה חובה</span> }
                </span>
                
                <span>
                    <input 
                        type="email" 
                        placeholder="כתובת דואל*" 
                        required
                        value={stateFieldEmail}
                        onChange={(event) => { setStateFieldEmail(event.target.value) }}
                    />
                    { ValidateEmail(stateFieldEmail) || !stateFormSend || <span className="FormValidationMessage">*שדה דואל אינו חוקי</span> }
                </span>
                
                <span>
                    <input
                        type="tel"
                        placeholder="טלפון*"
                        value={stateFieldPhone}
                        onChange={(event) => { setStateFieldPhone(event.target.value) }}
                        required
                        pattern="\d{2,3}-\d{7}"
                    />
                    { ValidatePhone(stateFieldPhone) || !stateFormSend || <span className="FormValidationMessage">*שדה טלפון אינו חוקי</span> }
                </span>

                <span className='form-subject'>
                    <input
                        type="text"
                        placeholder="נושא*"
                        required
                        value={stateFieldSubject}
                        onChange={(event) => { setStateFieldSubject(event.target.value) }}
                    />
                    { ValidateMandatory(stateFieldSubject) || !stateFormSend || <span className="FormValidationMessage">*שדה חובה</span> }
                </span>

                <span className='form-message'>
                    <textarea 
                        type="text"
                        placeholder="תיאור קצר של הנושא המשפטי*"
                        required                
                        value={stateFieldMessage}
                        onChange={(event) => { setStateFieldMessage(event.target.value) }}
                    ></textarea>
                    { ValidateMandatory(stateFieldMessage) || !stateFormSend || <span className="FormValidationMessage">*שדה חובה</span> }
                </span>

                <button className='form-submit' style={ (props.type === 'ContactUs') 
                                    ? {background: 'var(--color-brown)', width: 'max-content', fontWeight: 'bold', color: 'var(--color-icon)'}
                                    : {backgroundColor: 'var(--color-brown)', width: 'maxContent',  fontWeight: 'bold', color: 'var(--color-icon)'}
                        }
                        onClick={ onButtonClick }
                >
                    {
                        getStatusButton(stateFormSending)
                    }                
                </button>
            </div>
            
            { 
                (stateFormSending === 'sent' || stateFormSending === 'error') &&
                <>
                    <div style={ { position: 'absolute',top:0, left:0, width:'100%', height:'100%', backgroundColor:'white', opacity:'0.7'} }></div>
                    <div style={ { position:'absolute', top:'25%', left:'25%', width:'50%', height:'50%', backgroundColor:'white', border:'1px solid gray', borderRadius:'4px', display:'flex', justifyContent:'center', alignContent:'center', flexDirection:'column', gap:'20px', textAlign:'center'} }>{ getStatusMessage(stateFormSending)  }</div>
                </>
            }
        </>
    );
};

export default Form;


const IconArrowUp = (props) => {

    return (
        <svg role="presentation" viewBox="0 0 24 24" style={ props.style }>
            <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" ></path>
        </svg>
    );

};

export default IconArrowUp;